<template>
  <div class="flex flex-row gap-5 ml-5">
    <generic-filters :filter="filter" @resetFilters="resetFilters">
      <form-field label="Zoek adres" type="text" v-model="search" />

      <form-field
        label="Status"
        placeholder="Alle statussen"
        :options="statusOptions"
        mode="tags"
        type="multi"
        v-model="filter.statuses"
      />

      <form-field
        v-if="wocos?.length > 1"
        label="Woco"
        nullOption="Alle wocos"
        :options="wocos"
        mode="tags"
        type="multi"
        v-model="filter.wocos"
      />

      <form-field
        v-show="showProjectYearFilter"
        label="Project jaar"
        nullOption="Geen keuze"
        :options="yearOptions"
        type="select"
        v-model="filter.year"
        placeholder="Alle jaren"
      />

      <form-field
        label="Project"
        :options="projects"
        mode="tags"
        type="multi"
        v-model="filter.projects"
        placeholder="Alle projecten"
      />

      <form-field
        label="Groep"
        placeholder="Alle groepen"
        :options="groupsOptions"
        mode="tags"
        type="multi"
        v-model="filter.groups"
      />
    </generic-filters>
    <div class="w-full grid grid-cols-6 gap-3">
      <div class="m-2 col-span-4">
        <locations-list
          :locations="locations"
          :has-next-page="hasNextPage"
          :is-loading="isLoading"
          :numberOfLocations="numberOfLocations"
          @fetchNextPage="fetchNextPage()"
          @downloadExport="downloadExport"
          :filter="filter"
        />
      </div>
      <div class="col-span-2" v-if="typeof $route.params.id !== 'undefined' && $route.params.id">
        <location-detail :id="$route.params.id" @filter="resetFilters($event)" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, reactive, ref, watch } from 'vue'
import { watchDebounced } from '@vueuse/core'
import { useQuery, useInfiniteQuery } from '@tanstack/vue-query'
import fetchData from '@/util/fetch'
import GenericFilters from '@/components/shared/GenericFilters.vue'
import FormField from '@/components/shared/FormField.vue'
import LocationsList from '@/components/LocationsList.vue'
import LocationDetail from '@/components/LocationDetail.vue'
import { useNotificationsStore } from '@/store/notifications'
import { getCookie } from '@/util/fetch.js'

const notifications = useNotificationsStore()
const search = ref('')
const filter = reactive({
  projects: [],
  groups: [],
  statuses: [],
  wocos: [],
  year: null,
  query: '',
})

const nextYear = new Date().getFullYear() + 1
const yearOptions = Array.from({ length: nextYear - 2018 + 1 }, (_, i) => 2018 + i)

const getGroups = () => fetchData(`/groups/api/v1/group?app=B2B`)
const groupQuery = useQuery({
  queryKey: ['groups'],
  queryFn: getGroups,
})
const groups = computed(() => {
  const allGroups = groupQuery.data.value?.data || []

  if (filter.projects.length) {
    const projectNames = projects.value
      .filter((project) => filter.projects.includes(project.value))
      .map((p) => p.label)

    return allGroups.filter((group) => projectNames.includes(group.project))
  } else if (filter.year) {
    const projectNames = projects.value.map((p) => p.label)

    return allGroups.filter((group) => projectNames.includes(group.project))
  } else if (filter.wocos.length) {
    const wocoNames = wocosQuery.data.value?.data
      .filter((woco) => filter.wocos.includes(woco.shortname))
      .map((woco) => woco.name)

    return allGroups.filter((group) => wocoNames.includes(group.woco))
  } else {
    return allGroups
  }
})

const groupsOptions = computed(() =>
  groups.value.map((group) => ({ value: group.id, label: group.name }))
)

const getProjects = () => fetchData('/config/api/v1/projects/?view=full')
const projectsQuery = useQuery({
  queryKey: ['projects'],
  queryFn: getProjects,
})

watch(
  () => filter.year,
  () => {
    if (filter.year) {
      filter.projects = []
    }
  }
)

const projects = computed(() => {
  let projects = projectsQuery.data.value?.data || []
  if (filter.wocos.length) {
    const wocoFullNames = wocosQuery.data.value?.data
      .filter((woco) => filter.wocos.includes(woco.shortname))
      .map((woco) => woco.name)
    projects = projects.filter((prj) => wocoFullNames.includes(prj.woco))
  }
  if (filter.year) {
    projects = projects.filter((prj) => prj.year == filter.year)
  }
  return projects.map((prj) => ({ value: prj.id, label: prj.name }))
})

const getWocos = () => fetchData('/config/api/v1/wocos/?view=full')
const wocosQuery = useQuery({
  queryKey: ['wocos'],
  queryFn: getWocos,
})
const wocos = computed(() =>
  wocosQuery.data.value?.data.map((w) => ({ label: w.name, value: w.shortname }))
)

const statuses = {
  finished: 'afgerond',
  created: 'te plannen',
  design: 'ontwerp',
  sales: 'werving',
  inspection: 'schouw',
  installation: 'installatie',
  cancelled: 'geannuleerd',
}

const statusOptions = Object.entries(statuses).map(([key, value]) => ({
  value: key,
  label: value,
}))

function resetFilters(input = {}) {
  search.value = ''
  const defaultFilter = {
    projects: [],
    groups: [],
    statuses: [],
    wocos: [],
    year: null,
    query: '',
  }
  Object.assign(filter, {
    ...defaultFilter,
    ...input,
  })
}

function filterUrlParams(additionalParams = {}) {
  const { statuses, groups, wocos, year } = filter

  const urlParams = new URLSearchParams({
    ...additionalParams,
    query: search.value,
  })
  if (filter.projects.length) {
    for (const p of filter.projects) {
      urlParams.append('project_ids', p)
    }
  }
  // only apply year filter if there is no project filter
  else if (year) {
    if (!projects.value.length) {
      // there is no project for this year, so we want to show no items
      urlParams.append('project_ids', -1)
    }
    for (const p of projects.value) {
      urlParams.append('project_ids', p.value)
    }
  }
  if (groups) {
    for (const g of groups) {
      urlParams.append('group_ids', g)
    }
  }
  if (wocos) {
    for (const w of wocos) {
      urlParams.append('woco_names', w)
    }
  }
  if (statuses) {
    for (const s of statuses) {
      urlParams.append('status', s)
    }
  }

  return urlParams
}

const perPage = 30
const getLocations = ({ pageParam = 1 }) => {
  const urlParams = filterUrlParams({
    view: 'list',
    offset: (pageParam - 1) * perPage,
    limit: perPage,
  })
  return fetchData(`/tickets/api/v1/locations?${urlParams}`)
}

async function downloadExport() {
  const urlParams = filterUrlParams({
    view: 'excel',
  })
  const result = await fetch(`/tickets/api/v1/locations?${urlParams}`, {
    headers: {
      Authorization: `Bearer ${getCookie('access_token')}`,
    },
  })

  if (result.ok) {
    const filename = 'export.xlsx'
    const element = document.createElement('a')
    const blob = new Blob([await result.blob()], {
      type: 'application/xlsx',
    })
    element.href = window.URL.createObjectURL(blob)
    element.setAttribute('download', filename)
    element.click()
  } else {
    let message = 'Kon export niet downloaden'
    try {
      const json = await result.json()
      if (json?.message) {
        message = json.message
      }
    } catch (error) {
      console.error(error)
    }
    notifications.add(message, 'error')
  }
}

watchDebounced(
  search,
  () => {
    filter.query = search.value
  },
  {
    debounce: 300,
  }
)

const {
  data: locationsQuery,
  isLoading,
  fetchNextPage,
  hasNextPage,
} = useInfiniteQuery({
  queryKey: computed(() => ['locationAddresses', filter]),
  keepPreviousData: true,
  queryFn: getLocations,
  getNextPageParam: (_, allPages) =>
    allPages[allPages.length - 1].data.length === perPage ? allPages.length + 1 : undefined,
})

const numberOfLocations = computed(() => locationsQuery?.value?.pages[0].total)
const locations = computed(() => locationsQuery?.value?.pages.flatMap((p) => p.data) || [])

const showProjectYearFilter = computed(
  () => filter.wocos.length || (wocos?.value && wocos.value?.length === 1)
)
</script>
