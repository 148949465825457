import store from '@/store'

export async function userIsLoggedIn() {
  try {
    const response = await fetch('/auth/api/v1/me')
    const json = await response.json()

    return response.ok && json.status === 'OK'
  } catch (err) {
    console.error('Logged status request failed:', err.message)
    return false
  }
}

export async function requestLogin(email) {
  store.commit('setUserEmail', email)
  try {
    const response = await fetch(`/auth/api/v1/otps`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify({ email }),
    })
    const json = await response.json()
    return json.status === 'OK'
    // return json.message.toLowerCase() === 'user found, otp send';
  } catch (err) {
    console.error(err.message)
    return false
  }
}

export async function loginWithOtp(otp) {
  const data = {
    otp,
    email: store.state.userEmail,
    app: 'b2b',
  }
  try {
    const response = await fetch('/auth/api/v1/jwts', {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify(data),
    })
    if (response.status === 200) {
      store.commit('setLoggedIn', true)
      return true
    }
    return false
  } catch (err) {
    console.error(err.message)
    return false
  }
}

export async function logout() {
  try {
    const response = await fetch('/api/v1/logout')
    if (response.status === 200) {
      store.commit('setSelectedWoco', null)
      store.commit('setLoggedIn', false)
      store.commit('resetUser')
      // store.commit('setUserEmail', null);
      return true
    }
    console.log('LOGOUT FAILED')
    return false
  } catch (err) {
    console.error('LOGOUT ERROR:', err.message)
  }
  return false
}

export async function verifyUserLoginStatus() {
  const isLoggedIn = await userIsLoggedIn()
  if (isLoggedIn) {
    const response = await fetch('/api/user/details/')
    const jsonResponse = await response.json()
    const user = jsonResponse.data
    store.commit('setUser', user)
  }
  store.commit('setLoggedIn', isLoggedIn)
}
