var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(_setup.isLoading)?_c('div',{staticClass:"flex gap-5 items-center"},[_c('div',{staticClass:"border-gray-300 m-5 h-10 w-10 animate-spin rounded-full border-8 border-t-green-600"}),_vm._v(" Loading... ")]):(_setup.error || !_setup.location || _setup.location.status === 'error')?_c('div',[_vm._v(" Something went wrong or case does not exist. "+_vm._s(_setup.error)+" ")]):_c('div',[_c('div',{staticClass:"bg-white drop-shadow-xl rounded-b-lg p-3"},[_c('div',{staticClass:"font-bold tracking-wider text-lg text-gray-500 my-3"},[_vm._v(" "+_vm._s(_setup.location.address.street)+" "+_vm._s(_setup.location.address.house_number)+" ")]),_c('div',{staticClass:"rounded-lg inline p-1 text-white bg-blue-200",class:{
          'bg-pink-200': _setup.location.status.overall.status === 'cancelled',
          'bg-emerald-200': _setup.location.status.overall.status === 'finished',
        }},[_vm._v(" "+_vm._s(_setup.translateStatus(_setup.location))+" ")]),(_setup.location.status.design.comment && _setup.location.status.design.status == 'cancelled')?_c('div',{staticClass:"mt-3"},[_c('div',{staticClass:"text-sm"},[_vm._v("Reden legplan geannuleerd:")]),_c('div',[_vm._v(_vm._s(_setup.location.status.design.comment))])]):_vm._e(),(_setup.project)?_c('div',{staticClass:"flex mt-2 gap-2"},[_c('span',{staticClass:"font-bold"},[_vm._v("Project")]),_c('span',[_c('button',{staticClass:"underline",on:{"click":function($event){return _vm.$emit('filter', { projects: [_setup.project.id] })}}},[_vm._v(" "+_vm._s(_setup.project.name)+" ")])])]):_vm._e(),(_setup.location.installation)?_c('div',{staticClass:"flex mt-2 gap-2"},[_c('span',{staticClass:"font-bold"},[_vm._v("Aantal panelen")]),_c('span',[_vm._v(" "+_vm._s(_setup.location.installation.panel.amount)+"x "+_vm._s(_setup.location.installation.panel.name)+" ")])]):_vm._e(),(_setup.location.installation)?_c('div',{staticClass:"flex mt-2 gap-2"},[_c('span',{staticClass:"font-bold"},[_vm._v("Installatiedatum")]),_c('span',[_vm._v(" "+_vm._s(_setup.formatDate(_setup.location.installation.appointment_date))+" ")])]):_vm._e(),(_setup.location.eleenas?.length)?_c('div',{staticClass:"flex mt-2 gap-2"},[_c('div',{staticClass:"font-bold"},[_vm._v("Eleena eerste productie")]),_c('div',[_vm._v(_vm._s(_setup.formatDate(_setup.location.eleenas[0].first_production_at)))])]):_vm._e(),_c('div',{staticClass:"flex gap-2 flex-row justify-between mt-3"},[(_setup.location._links.asbuilt)?_c('a',{staticClass:"bg-gray-500 rounded px-1 text-white flex items-center pointer",attrs:{"target":"_blank","href":_setup.location._links.asbuilt}},[_c(_setup.DocumentTextIcon,{attrs:{"size":"20"}}),_c('div',{staticClass:"px-1"},[_vm._v("Asbuilt PDF")])],1):_vm._e(),(
            _setup.location._links.sales_contract_letter_pdf ||
            _setup.location._links.sales_contract_signrequest
          )?_c('a',{staticClass:"bg-gray-500 rounded px-1 text-white flex items-center pointer",attrs:{"target":"_blank","href":_setup.location._links.sales_contract_letter_pdf ||
            _setup.location._links.sales_contract_signrequest}},[_c(_setup.DocumentTextIcon,{attrs:{"size":"20"}}),_c('div',{staticClass:"px-1"},[_vm._v("Akkoordverklaring")])],1):_vm._e()])])]),(_setup.showEditModal)?_c(_setup.GenericModal,{attrs:{"title":"Bewerk bewonersdetails"},on:{"close":function($event){_setup.showEditModal = false},"submit":function($event){_setup.showEditModal = false}}},[_vm._v(" Bewonersgegevens bewerken ")]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }